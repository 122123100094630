import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
  MutableRefObject,
} from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { UseCustomCarrouselProps } from './props';
import { useIsMobile } from '../useIsMobile';

export function useVirtualizedCarousel(options?: UseCustomCarrouselProps) {
  const isMobile = useIsMobile();
  const {
    onStepChange,
    size,
    autoScroll,
    timeout,
    currentIndex,
    scrollToCurrentOnEnter,
    slideBy = 1,
    direction,
    children,
    containerRef,
  } = options ?? {};

  const isFirstMount: MutableRefObject<boolean> = useRef<boolean>(true);
  const childRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [childSize, setChildSize] = useState({ width: 0, height: 0 });
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const childCount = React.Children.toArray(children).length;
  const itemsPerView = size ?? 1;

  const maxSteps = Math.max(
    Math.ceil((childCount - itemsPerView) / slideBy) + 1,
    1,
  );

  const virtualizer = useVirtualizer({
    count: childCount,
    estimateSize: () => childSize.width,
    getScrollElement: () => containerRef.current,
    horizontal: true,
    enabled: typeof window != 'undefined',
    getItemKey(index) {
      return children?.[index]?.toLocaleString();
    },
    gap: 16,
  });

  const items = virtualizer.getVirtualItems();

  const padding = useMemo(() => {
    if (items.length === 0) {
      return { paddingLeft: 0, paddingRight: 0 };
    }

    const firstItem = items[0];
    const lastItem = items[items.length - 1];
    const totalSize = virtualizer.getTotalSize();

    return {
      paddingLeft: Math.max(0, firstItem.start),
      paddingRight: Math.max(0, totalSize - lastItem.end),
    };
  }, [items, virtualizer]);

  const calculateChildSize = useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }

    requestAnimationFrame(() => {
      const firstChild = childRefs.current.find(child => child);
      if (!firstChild) {
        return;
      }

      virtualizer.measureElement(firstChild);

      const rect = firstChild.getBoundingClientRect();
      const width = rect.width;
      const height = rect.height;

      setChildSize({ width, height });
    });
  }, [virtualizer]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      calculateChildSize();
    }, 0);

    return () => clearTimeout(timeout);
  }, [calculateChildSize, children, isMobile]);

  const handleDrag = useCallback(
    (direction: 'left' | 'right') => {
      if (!virtualizer) return;

      const childWidthWithGap = childSize.width || 220;
      let newStep: number;

      if (direction === 'left') {
        newStep = Math.max(currentStep - 1, 0);
        virtualizer.scrollBy(-childWidthWithGap * slideBy, {
          behavior: 'smooth',
        });
      } else {
        newStep = Math.min(currentStep + 1, maxSteps - 1);
        virtualizer.scrollBy(childWidthWithGap * slideBy, {
          behavior: 'smooth',
        });
      }

      setCurrentStep(newStep);
      onStepChange?.(newStep);
    },
    [
      currentStep,
      maxSteps,
      slideBy,
      onStepChange,
      childSize.width,
      virtualizer,
    ],
  );

  const handleInitialScroll = useCallback(() => {
    if (scrollToCurrentOnEnter && currentIndex && containerRef.current) {
      const childWidthWithGap = childSize.width || 220;
      virtualizer.scrollBy(childWidthWithGap * currentIndex, {
        behavior: 'smooth',
      });
    }
  }, [
    scrollToCurrentOnEnter,
    currentIndex,
    childSize.width,
    containerRef,
    virtualizer,
  ]);

  useEffect(handleInitialScroll, [handleInitialScroll]);

  useEffect(() => {
    calculateChildSize();

    const handleResize = () => {
      calculateChildSize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateChildSize, children, isMobile]);

  const handleStepListener = useCallback(() => {
    const container: HTMLDivElement = containerRef.current;

    if (!container) return;

    const childWidth: number = container.children[0]?.clientWidth + 16;

    const exactStep: number = virtualizer.scrollOffset / (childWidth * slideBy);
    const predictedStep: number = Math.round(exactStep);
    const boundedStep: number = Math.max(
      0,
      Math.min(predictedStep, maxSteps - 1),
    );

    if (currentStep === boundedStep) {
      return;
    }

    setCurrentStep(boundedStep);
    onStepChange?.(boundedStep);
  }, [currentStep, onStepChange, slideBy, maxSteps, virtualizer, containerRef]);

  useEffect(handleStepListener, [containerRef, handleStepListener]);

  function handleScrollToCurrentOnEnter() {
    if (
      !scrollToCurrentOnEnter ||
      !virtualizer ||
      !currentIndex ||
      !isFirstMount.current
    ) {
      return;
    }

    isFirstMount.current = false;
    const childWidthWithGap = childSize.width || 220;
    virtualizer.scrollBy(childWidthWithGap * 10, {
      behavior: 'smooth',
    });
  }

  useEffect(handleScrollToCurrentOnEnter, [
    currentIndex,
    scrollToCurrentOnEnter,
    virtualizer,
    childSize,
  ]);

  const handleAutoScrollInterval = useCallback(() => {
    if (!virtualizer) {
      return;
    }

    const childWidth: number = childSize.width + 16;
    const scrollAmount: number = childWidth * slideBy;

    if (direction === 'right') {
      if (virtualizer.scrollOffset <= 0) {
        virtualizer.scrollBy(0, { behavior: 'smooth' });
        setCurrentStep(maxSteps - 1);
        return;
      }
      virtualizer.scrollBy(scrollAmount, {
        behavior: 'smooth',
      });
      setCurrentStep(prev => Math.max(prev - 1, 0));
    } else {
      if (virtualizer.scrollOffset >= virtualizer.getTotalSize()) {
        virtualizer.scrollBy(0, { behavior: 'smooth' });
        setCurrentStep(0);
        return;
      }
      virtualizer.scrollBy(scrollAmount, {
        behavior: 'smooth',
      });
      setCurrentStep(prev => Math.min(prev + 1, maxSteps - 1));
    }
  }, [slideBy, maxSteps, direction, virtualizer, childSize]);

  const handleAutoScroll = useCallback(() => {
    if (!autoScroll) {
      return;
    }

    if (intervalId) {
      clearInterval(intervalId);
    }

    const id: NodeJS.Timeout = setInterval(
      handleAutoScrollInterval,
      timeout ?? 3500,
    );
    setIntervalId(id);

    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoScroll, handleAutoScrollInterval, timeout]);

  useEffect(handleAutoScroll, [autoScroll, handleAutoScroll]);

  return {
    containerRef,
    virtualizer,
    handleDragLeft: () => handleDrag('left'),
    handleDragRight: () => handleDrag('right'),
    currentStep,
    childSize,
    steps: maxSteps,
    childRefs,
    padding,
  };
}
