import React, { PropsWithChildren, ReactNode, useMemo, useRef } from 'react';
import { SliderProps } from './props';
import styles from './styles.module.scss';
import LoadMore from './LoadMore';
import { NoDataComponent } from '../NoDataComponent';
import { useVirtualizedCarousel } from '@starsoft/common/hooks/useVirtualizedCarousel';
import { useIsMobile } from '@starsoft/common/hooks';
import SliderStepper from './Content/Stepper';

export function Slider({
  size,
  timeout,
  children,
  paginated,
  className,
  mobileSize,
  hideButtons,
  slideBy = 1,
  currentIndex,
  onStepChange,
  useAutoScroll,
  absoluteButton,
  customComponents,
  showButtonsOnScroll,
  scrollToCurrentOnEnter,
  direction,
}: PropsWithChildren<SliderProps>) {
  const childCount = React.Children.toArray(children).length;
  const isMobile = useIsMobile();
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    virtualizer,
    handleDragLeft,
    handleDragRight,
    childRefs,
    childSize,
    steps,
    currentStep,
  } = useVirtualizedCarousel({
    autoScroll: useAutoScroll,
    timeout,
    slideBy,
    size,
    currentIndex,
    children: children as unknown as ReactNode[],
    onStepChange,
    containerRef,
    direction,
    scrollToCurrentOnEnter,
  });

  const itemsPerView = useMemo(
    () => (isMobile && mobileSize ? mobileSize : size),
    [isMobile, mobileSize, size],
  );

  const isScrollable =
    !showButtonsOnScroll || (showButtonsOnScroll && steps > 1);

  if (childCount === 0) {
    return <NoDataComponent />;
  }

  console.log(childSize);

  return (
    <div
      className={`${styles.container} ${className || ''}`}
      style={
        {
          '--items-per-view': itemsPerView,
        } as React.CSSProperties
      }
    >
      {customComponents?.header
        ? customComponents?.header({
            onDragLeft: () => handleDragLeft(),
            onDragRight: () => handleDragRight(),
          })
        : null}
      <div
        className={`${styles.container__content}`}
        ref={containerRef}
        style={{
          height: childSize.height === 0 ? undefined : childSize.height,
        }}
      >
        {typeof window !== 'undefined'
          ? virtualizer.getVirtualItems().map(item => (
              <div
                key={String(item.index) + String(childSize.width)}
                ref={el => (childRefs.current[item.index] = el)}
                data-index={item.index}
                style={{
                  position: childSize.height === 0 ? 'relative' : 'absolute',
                  top: 0,
                  left: 0,
                  width: childSize.width === 0 ? 'auto' : childSize.width,
                  height: childSize.height === 0 ? 'auto' : childSize.height,
                  transform:
                    childSize.height === 0
                      ? 'unset'
                      : `translateX(${item.start}px)`,
                }}
              >
                {children[item.index]}
              </div>
            ))
          : React.Children.toArray(children)
              .slice(0, 6)
              .map(child => child)}
        {paginated && !paginated?.loading && <LoadMore {...paginated} />}
      </div>

      {!hideButtons && isScrollable && steps > 1 && (
        <SliderStepper
          currentStep={currentStep as number}
          onStepClick={step => virtualizer.scrollToIndex(step)}
          steps={steps}
          absolute={absoluteButton}
        />
      )}
    </div>
  );
}
